import type { BadgeColor } from '#ui/types'
import { InvoiceType } from '~/types/misc'

export const badgeTypes: Record<
  InvoiceType,
  { color: BadgeColor; label: string }
> = {
  [InvoiceType.INVOICE_RECEIPT]: { color: 'green', label: 'Fatura-Recibo' },
  [InvoiceType.INVOICE]: { color: 'amber', label: 'Fatura' },
  [InvoiceType.RECEIPT]: { color: 'fuchsia', label: 'Recibo' },
  [InvoiceType.CREDIT_NOTE]: { color: 'blue', label: 'Nota de Crédito' },
  [InvoiceType.DEBIT_NOTE]: { color: 'red', label: 'Nota de Débito' },
}

export function calculateTotalPrices(
  items: {
    id: number
    name: string
    price: number
    quantity: number
    discount: number
    vat: number
    calculatedPrice: number
    total: number
  }[],
) {
  let subtotal = 0
  let vat = 0
  let total = 0
  let totalDiscount = 0

  if (items) {
    for (const item of items) {
      if (!item.price || Number.isNaN(item.price)) continue

      const itemPrice = item.price * item.quantity
      const discountAmount = itemPrice * (item.discount / 100)
      totalDiscount += discountAmount

      const itemSubtotal = itemPrice - discountAmount
      subtotal += itemSubtotal
      vat += itemSubtotal * (item.vat / 100)

      if (item.calculatedPrice) {
        total += Number(item.calculatedPrice)
      } else {
        total += Number(item.total)
      }
    }
  }

  const formattedSubtotal = formatCurrency(subtotal.toFixed(2))
  const formattedVat = formatCurrency(vat.toFixed(2))
  const formattedTotal = formatCurrency(Number(total).toFixed(2))
  const formattedDiscount = formatCurrency(totalDiscount.toFixed(2))

  return {
    subtotal: formattedSubtotal,
    vat: formattedVat,
    total: formattedTotal,
    discount: formattedDiscount,
  }
}
